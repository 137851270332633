@import '../../../../../styles/shared';

.anchor {
  display: block;
  text-decoration: none;
  color: var(--hub-nav-color);
  transition: border-bottom-color 0.4s;
  min-width: 65px;

  @include mq($from: desktop) {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  &:hover {
    border-bottom-color: var(--hub-nav-color);
  }
}

.label {
  font-size: 20px;
  white-space: nowrap;

  @include mq($from: desktop) {
    font-size: 16px;
    font-weight: var(--hub-nav-font-weight);
  }

  @include mq($from: desktop) {
    display: none;
  }
}

.icon {
  font-size: 24px;

  @include mq($until: desktop) {
    display: none;
  }
}

:global {
  .hub-nav-home-item.active {
    border-bottom: 1px solid var(--hub-nav-active-border-color);

    @include mq($until: desktop) {
      font-weight: bold;
    }
  }
}
