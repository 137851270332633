@import '../../../../../styles/shared';

.button {
  white-space: nowrap;

  @include mq($until: desktop) {
    padding: 12px 24px;
    font-size: 18px;
  }
}
