@import '../../../../styles/shared';

$size: 34px;

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-width: $size;
  min-height: $size;

  input {
    width: 100%;
    height: $size;
    margin: 0;
    padding: 0 35px 0 15px;
    font-size: 14px;
    appearance: none;
    color: #000;
    border: 1px solid #000;
    border-radius: calc($size / 2);

    &:focus {
      outline: none;
      background: #efefef;
    }
  }
}

.asButton {
  input {
    display: none;
  }
}

.button {
  position: absolute;
  right: -1px;
}
