@import '../../../../../styles/shared';

.anchor {
  display: flex;
  text-decoration: none;
  color: var(--hub-nav-color);
  transition: border-bottom-color 0.3s;

  @include mq($from: desktop) {
    align-items: center;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  &:hover {
    border-bottom-color: var(--hub-nav-color);
  }
}

.label {
  font-size: 20px;
  white-space: nowrap;

  @include mq($from: desktop) {
    font-size: 14px;
    font-weight: var(--hub-nav-font-weight);
  }

  @include mq($from: wide) {
    font-size: 16px;
  }
}

:global {
  .hub-nav-item.active {
    border-bottom: 1px solid var(--hub-nav-active-border-color);

    @include mq($until: desktop) {
      font-weight: bold;
    }
  }
}
