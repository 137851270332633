@import '../../../../styles/shared';

.wrapper {
  font-family: var(--hub-nav-font-family);
  color: var(--hub-nav-color);
  background-color: var(--hub-nav-background-color);
  z-index: 1000;
  position: relative;

  @include mq($until: desktop) {
    padding: 30px 0;
  }

  & > :global(.container) {
    position: initial;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include mq($from: desktop) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.reversed {
  @include mq($from: desktop) {
    flex-direction: row;
  }
}

.menu {
  @include mq($from: desktop) {
    display: flex;
    flex: 1 0;
    overflow-x: hidden;
  }
}

.menuRight {
  @include mq($from: desktop) {
    justify-content: flex-end;
  }
}

.brandLogoWrapper {
  display: flex;
  align-items: center;
}

.brandLogo {
  height: 40px;
}

.brandLogoAnchor {
  display: flex;
}

.searchWrapper {
  display: none;

  @include mq($from: desktop) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.searchField {
  display: none;

  @include mq($from: wide) {
    display: block;
    min-width: 330px;
  }
}

.searchButton {
  display: none;

  @include mq($from: desktop, $until: wide) {
    display: block;
  }
}

.list {
  display: flex;
  flex-direction: column;

  @include mq($from: desktop) {
    flex-direction: row;
    height: var(--hub-nav-height);
  }
}

.listItem {
  &:not(:first-child) {
    padding: 0 15px;

    @include mq($until: desktop) {
      padding: 0;
    }
  }

  &:last-child {
    padding-right: 0;
  }

  @include mq($from: desktop) {
    display: flex;
  }
}

.buttonElement {
  & + & {
    padding-left: 0;
  }
}

.buttonLinkWrapper {
  display: flex;
  align-items: center;

  @include mq($until: desktop) {
    position: relative;
    left: -$gutter-width;
    width: calc(100% + $gutter-width * 2);
    padding: 15px $gutter-width;
  }
}

.user {
  &:empty {
    display: none;
  }
}
