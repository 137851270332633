@import '../../../../../styles/shared';

$transition-duration: 0s;
$transition-delay: 0.3s;

.anchor {
  display: flex;
  flex-direction: column;
  color: var(--hub-nav-color);
  user-select: none;
  cursor: default;

  @include mq($from: desktop) {
    flex-direction: row;

    &:hover {
      .label {
        border-bottom-color: var(--hub-nav-color);
      }

      .icon {
        transform: rotateX(180deg);
      }

      .secondLevelMenu {
        display: block;
        top: var(--hub-nav-height);
        transform: rotateX(0);
      }
    }
  }
}

.label {
  display: flex;
  align-items: center;
  font-size: 20px;
  white-space: nowrap;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color $transition-duration;
  transition-delay: $transition-delay;

  @include mq($from: desktop) {
    font-size: 14px;
    font-weight: var(--hub-nav-font-weight);
  }

  @include mq($from: wide) {
    font-size: 16px;
  }
}

.icon {
  margin: 2px -7px 0 5px;
  transition: transform $transition-duration;
  transition-delay: $transition-delay;
}

.secondLevelMenu {
  display: none;
  margin: 10px (-$gutter-width) 0;

  @include mq($from: desktop) {
    position: absolute;
    display: block;
    margin: 0;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    transition: $transition-duration;
    transition-delay: $transition-delay;
    transform: rotateX(90deg);
  }
}

.open .secondLevelMenu {
  display: block;
}

:global {
  .hub-nav-group-item.active {
    border-bottom: 1px solid var(--hub-nav-active-border-color);

    @include mq($until: desktop) {
      font-weight: bold;
    }
  }
}
