@import '../../../../../styles/shared';

.wrapper {
  padding: 18px 0;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  background: var(--hub-nav-dropdown-background-color);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include mq($from: desktop) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    row-gap: 10px;
  }
}

.listItem {
  @include mq($until: desktop) {
    &:not(:last-child)::after {
      content: '';
      display: block;
      margin-top: 10px;
      opacity: 0.2;
      border-top: 1px solid rgba(255, 255, 255, 0.58);
    }
  }
}

.anchor {
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;
  color: var(--hub-nav-color);

  &:hover {
    border-bottom: 1px solid var(--hub-nav-color);
  }

  @include mq($from: wide) {
    font-size: 16px;
  }
}

.alignRight {
  @include mq($from: desktop) {
    justify-content: flex-end;
  }
}

:global {
  .hub-nav-sub-item.active {
    font-weight: 700;

    @include mq($from: desktop) {
      font-weight: 400;
      border-bottom: 1px solid var(--hub-nav-active-border-color);
    }
  }
}
